import React from 'react';
import { ThemeProvider } from "@ct/assemblage";
import { LightboxProvider } from "./LightboxContext";
import LightboxModal from "./LightboxModal";

function Lightbox({selector = 'lightbox', version}) {
  return (
    <ThemeProvider>
      <LightboxProvider selector={selector} version={version}>
        <LightboxModal/>
      </LightboxProvider>
    </ThemeProvider>
  );
}

export default Lightbox;
