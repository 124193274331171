import React from 'react';
const LightboxContext = React.createContext();

function LightboxProvider({selector = 'lightbox', version, children}) {
  const lightboxSelector = `#${selector}`;
  const referrer = document?.referrer ?? '';

  const isGuest = document?.querySelector(lightboxSelector).dataset?.username?.toLowerCase() === 'guest'|| false;
  const noRegister = document?.querySelector(lightboxSelector).dataset?.noregister?.toLowerCase() === 'true' || false;
  const iWine =
    new URLSearchParams(window.location.search).get('iWine') ??
    document?.querySelector(lightboxSelector).dataset?.iwine;
  const isMobile = document?.querySelector(lightboxSelector).dataset?.mobile?.toLowerCase() === 'true' || false;
  const isNative = document?.querySelector(lightboxSelector).dataset?.native?.toLowerCase() === 'true' || false;
  const value = {
    iWine,
    isGuest,
    noRegister,
    campaign: version,
    referrer,
    isMobile,
    isNative,
  };
  return <LightboxContext.Provider value={value}>{children}</LightboxContext.Provider>;
}

function useLightbox() {
  const context = React.useContext(LightboxContext);
  if (context === undefined) {
    throw new Error(`useLightbox must be used within a LightboxProvider`);
  }
  return context;
}

export {LightboxProvider, useLightbox};
