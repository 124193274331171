import React from "react";
import { createRoot } from "react-dom/client";
import Lightbox from "./components/Lightbox";

const lightboxSelector = "lightbox";
const lightboxVersion = 7;
const lightboxContainer = document.getElementById(lightboxSelector);
const lightboxRoot = createRoot(lightboxContainer);

lightboxRoot.render(
  <React.StrictMode>
    <Lightbox selector={lightboxSelector} version={lightboxVersion} />
  </React.StrictMode>
);