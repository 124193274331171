import {Sheet, Link} from '@mui/joy';
import {useForm} from 'react-hook-form';
import {FormInput, ModalHeader, ModalDescription, Button} from '@ct/assemblage';
import {checkEmail, register as registerUser} from '../../api';
import {useGA} from '../../hooks';
import {useLightbox} from '../LightboxContext';

const DEFAULT_VALUES = {
  email: '',
};

const EmailForm = ({formStep, nextFormStep, onSubmitSuccess}) => {
  const {track} = useGA();
  const {iWine, campaign, referrer, isMobile} = useLightbox();
  const signInUrl = isMobile ? `/m/signin` : `/password.asp`;
  const forgotPasswordUrl = isMobile ? `/m/signin/reminder` : `/reminder.asp`;
  const privacyPolicyUrl = isMobile ? '/m/privacy' : '/privacypolicy.asp';
  const termsOfServiceUrl = isMobile ? '/m/terms' : '/terms.asp';

  const {
    handleSubmit,
    setError,
    formState: {errors, isSubmitting, isValid},
    register,
  } = useForm({defaultValues: DEFAULT_VALUES});

  const trackInput = ({name, label}) =>
    track({
      name: 'lightbox_focus_text_input',
      action: 'Focus',
      object: 'Text Input',
      version: campaign,
      label: label,
      internal_label: name,
    });

  const trackClick = event => {
    track({
      name: 'lightbox_press_link',
      action: 'Press',
      object: 'Link',
      version: campaign,
      label: event.target.innerText,
    });
    return false;
  };

  const onSubmit = async values => {
    track({
      name: 'lightbox_press_sign_up_button',
      action: 'Press',
      category: 'Lightbox',
      object: 'Sign Up Button',
      version: campaign,
    });
    try {
      const result = await checkEmail(values);
      if (result?.error) {
        return setError('email', {
          type: 'user_exists',
          message:
            result.error?.message ||
            `An account already exists for this email, would you like to <a href="${forgotPasswordUrl}">reset your password?</a>`,
        });
      }
      const registerResult = await registerUser({
        ...values,
        meta: {iWine, referrer, campaign: `lb${campaign}`},
      });
      if (registerResult.errors) {
        for (let [field, {msg}] of Object.entries(registerResult.errors)) {
          setError(field, {type: 'manual', message: msg});
        }
        return;
      }
      onSubmitSuccess();
      return nextFormStep();
    } catch (error) {
      console.error(error);
      setError('email', {
        type: 'server',
        message: 'Sorry, we could not complete your request at this time.',
      });
    }
  };

  return (
    <Sheet
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      sx={{width: '100%', display: formStep === 0 ? 'block' : 'none'}}
    >
      <ModalHeader>Join the Community</ModalHeader>
      <ModalDescription>
        Manage your cellar, add reviews, and discover millions of tasting notes
        on CellarTracker.
      </ModalDescription>
      <FormInput
        label="Your Email"
        error={errors?.email}
        onFocus={() => trackInput({name: 'email', label: 'Your Email'})}
        {...register('email', {
          required: 'Email is required.',
          pattern: {
            value:
              /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            message: 'Please enter a valid email address.',
          },
        })}
      />
      <Button
        sx={{mt: 5}}
        fullWidth
        size="lg"
        type="submit"
        loading={isSubmitting}
        disabled={!isValid}
      >
        Sign Up
      </Button>
      <Sheet sx={{fontSize: 'xs', textAlign: 'center', marginTop: 4}}>
        <p>
          By continuing, you agree to CellarTracker’s <br />
          <Link
            href={termsOfServiceUrl}
            color="info"
            level="body3"
            target="_blank"
            rel="noreferrer noopener"
            onClick={trackClick}
          >
            Terms of Service
          </Link>{' '}
          and{' '}
          <Link
            href={privacyPolicyUrl}
            color="info"
            level="body3"
            target="_blank"
            rel="noreferrer noopener"
            onClick={trackClick}
          >
            Privacy Policy
          </Link>
          .
        </p>
      </Sheet>
      <Sheet sx={{fontSize: 'xs', textAlign: 'center', marginTop: 4}}>
        <p>
          Already a member?{' '}
          <Link
            href={signInUrl}
            color="info"
            level="body3"
            onClick={trackClick}
          >
            Sign In
          </Link>
        </p>
      </Sheet>
    </Sheet>
  );
};

export default EmailForm;
